import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { FaWhatsapp, FaEnvelope, FaExclamationCircle, FaUser } from 'react-icons/fa';

const CustomerService = () => {
  const token =  localStorage.getItem("token");

  const [data,setData] = useState({data:[]})

    useEffect(() => {
  
        axios.post('http://bpohasil.click:8080/ca/dashtable',{token:token})
            .then(res => setData(res.data)
        ) 
            .catch(err => console.log(err));
    }, []); 


  return (
    <div className="card px-1 py-2" >
      <p className="ms-2 mb-2 m-0 fw-bold">Customer Advance Payment</p>
      <div className='card-body p-1'>
      <table className="table">
        <thead>
          <tr>
            <th>Areas</th>
            <th>Total Customers</th>
            <th>Total Amount</th>
            <th>Total Percentage</th> 
          </tr>
        </thead>
        <tbody>
            <tr  className='my-2 text-center' >
              <td>Area 1</td>
              <td>20123</td>
              <td>RM 22379520</td>
              <td>84%</td>
            </tr>

            <tr  className='my-2 text-center' >
              <td>Area 2</td>
              <td>40133</td>
              <td>RM 1379520</td>
              <td>67%</td>
            </tr>
            
            <tr  className='my-2 text-center' >
              <td>Area 3</td>
              <td>801223</td>
              <td>RM 579520</td>
              <td>82%</td>
            </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default CustomerService;
