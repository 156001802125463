const Card2 = ({data}) => {

  return (
    <div className="card">

      <div className="card-header p-0">
        <h6 className="mb-0">Customer How advance pay</h6>
      </div>
      <div className="card-body p-0">
        <ul className="list-group">
        {data && data.tags.map((item,index)=>(
          <li key={index} className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div className="d-flex align-items-center">
            <div className="icon icon-sm me-3 bg-gradient-dark shadow d-flex justify-content-center align-items-center">
              <i className={`fs-6 ${item.icon} text-light opacity-10 `}></i>
            </div>
            <div className="d-flex flex-column">
              <h6 className="mb-1 text-dark text-sm">{item.title}</h6>
            </div>
          </div>
          <div className="d-flex">
            <button className="btn btn-link btn-icon-only  btn-rounded btn-sm text-dark icon-move-right my-auto"><i className="fa-solid fa-chevron-right " aria-hidden="true"></i></button>
          </div>
        </li>
      ))}
  
        </ul>
      </div>
    </div>
  );
}

export default Card2;