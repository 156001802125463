import { useEffect, useState } from "react";
import axios from "axios";
import Table from "../components/Index/Table";

import Card2 from "../components/Index/Card2";
import IMGCard from "../components/Index/IMGCard";
import SliderCard from "../components/SliderCard";
import LineChart from "../components/Index/LineChart";
import SimpleSlider from "../components/Slider";



const Index = () => {

    const [data, setData] = useState()
    const [id,setID] = useState(1)


    const handleID = () => {
        setID((prevID) => (prevID < 5 ? prevID + 1 : 1));
    };

    const handleID2 = () => {
        setID((prevID) => (prevID > 1 ? prevID - 1 : 5)); 
    };

    useEffect(() => {
        async function Handledata() {      
            const  response = await axios.post('http://bpohasil.click:8080/ca/getcardsvalues',{ id:id})
          setData(response.data)
        }
        Handledata()
     }, [id]); 

 
    
    return (
        <div className='container-fluid  p-2 p-lg-0'>

            {/* cards */}
           
            <div className=" mx-4 mx-lg-3">
              <SimpleSlider/>
             </div>
           
            

            {/* line charts */}
            <div className='row my-1 g-3'>
                <div className=' mb-3 mt-1  mb-lg-0'>
                    <div className="row g-3">
                        <div className="col-lg-7">
                            <div className='card p-0 shadow' style={{ maxWidth: '99%' }}>
                                <div className='card-body p-2'>
                                    <p className="ms-2 m-0 fw-bold">Aduan Selesai</p>
                                    <div className="pe-1">
                                        {data &&
                                        <LineChart data={data.chart}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <IMGCard />
                        </div>
                    </div>
                </div>

            </div>
            {/* table & Progress */}
            <div className="row mt-1 g-3">
                <div className="col-lg-7">
                    <Table data={data}/>
                </div>
                <div className="col-lg-5">
                    <Card2 data={data}/>
                </div>
            </div>
        </div>
    );
}

export default Index;
