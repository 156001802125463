import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { FaWhatsapp, FaEnvelope, FaExclamationCircle, FaUser } from 'react-icons/fa';

const CustomerService = ({data}) => {

  return (
    
    <div className="card px-1 py-2" >
      <p className="ms-2 mb-2 m-0 fw-bold">Customer Advance Payment</p>
      <div className='card-body p-1' style={{ maxWidth: "90%", overflow: "auto" }}>
      <table className="table">
        <thead>
          <tr>
            <th>Areas</th>
            <th>Total Customers</th>
            <th>Total Amount</th>
            <th>Total Percentage</th> 
          </tr>
        </thead>
        <tbody>
        {data && data.table.map((item,index)=>(

            <tr key={index} className='my-2 text-center' >
              <td>{item.Area}</td>
              <td>{item.TotalCustomers}</td>
              <td>{item.TotalAmount}</td>
              <td>{item.TotalPercentage}</td>
            </tr>
        ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default CustomerService;
