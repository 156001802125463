import WaterBayaran from "./UploadWaterBayaran";
import UploadWaterBills from "./UploadWaterBills";
import UploadWaterTunggakan from "./UploadWaterTunggakan";

const WaterUploader = () => {
    return (
        <div className="bg-white p-2 rounded">
            <h3 className="m-2">Water Files Upload</h3>
            <div className="m-2">
            <WaterBayaran/>
            </div>
            <div className="m-2">
            <UploadWaterBills/>
            </div>
            <div className="m-2">
            <UploadWaterTunggakan/>
            </div>
            
           
        </div>
      );
}
 
export default WaterUploader;