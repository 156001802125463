import { useState, useEffect } from "react";
const Card = ({percentage,value,title,icon,color}) => {
    const [operator, setOperator] = useState('+');

    
    const stringValue = String(value);
    useEffect(() => {
        if (stringValue.length > 1) {
            setOperator(stringValue[1] >= '0'  ? '+' : '-');
        } else {
            setOperator('-');
        }
    }, []);

    return (
        <div className="card   shadow mt-xl-0 p-0" style={{borderRadius:"15px"}}>
            <div className="card-body p-2">
                <div className="row d-flex ">
                    <div className="col-9 ">
                        <div className="card-title">
                            <p className="fw-bold p-0 m-0" style={{ fontSize: '85%',color:'gray' }}>{title}</p>
                        <p className="fw-bolder fs-5 m-0">{value}</p>
                        </div>
                        <div className={`card-text fw-bold`}  style={{ fontSize: '75%' }}>
                           
                            <span className={`${stringValue[1] >= "0" ? 'text-success' : 'text-danger'}`}>
                                {operator}{percentage}
                            </span>
                        </div>
                    </div>
                    <div className="col-3 d-flex justify-content-end " style={{maxHeight:'43px'}}>
                          < i className={`icon rounded-circle ${icon}`} style={{backgroundColor:`${color}`}}></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;
