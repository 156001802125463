import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart as ChartJS, BarElement, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, Filler, LineController } from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  BarElement, 
  LineElement, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  Legend, 
  Tooltip, 
  Filler, 
  LineController
);

const MixedChart = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios.post('http://bpohasil.click:8080/ca/dashchart', { token: token })
      .then(response => {
        const data = response.data;
        setChartData({
          labels: data.labels,
          datasets: [
            {
              type: 'line',
              label: 'Referral',
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              pointBackgroundColor: '#5e72e4',
              borderColor: '#5e72e4',
              borderWidth: 3,
              backgroundColor: 'rgba(94, 114, 228, 0)',
              data: data.data,
              fill: true,
            },
          ],
        });
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching the chart data');
        setLoading(false);
        console.error('Error fetching the chart data', error);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      }
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5]
        },
        ticks: {
          display: true,
          padding: 10,
          color: '#b2b9bf',
          font: {
            size: 11,
            family: 'Open Sans',
            style: 'normal',
            lineHeight: 2,
          },
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          borderDash: [5, 5],
        },
        ticks: {
          display: true,
          color: '#b2b9bf',
          padding: 10,
          font: {
            size: 11,
            family: 'Open Sans',
            style: 'normal',
            lineHeight: 2,
          },
        },
      },
    },
  };

  return (
    <div style={{ position: 'relative', height: '300px' }}>
      <Chart type='bar' data={chartData} options={options} />
    </div>
  );
};

export default MixedChart;
