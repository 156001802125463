import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Table from "../components/Rental/Table";
import Card from "../components/Rental/Card";

const Rental = () => {


     const [data, setData] = useState({ users: [] });
     const { id } = useParams();

     useEffect(() => {
          async function getAllCustomersKawasan() {
               const response = await axios.post('http://bpohasil.click:8080/ca/getallkawasancustomers',
                    { id: id }
               )
               console.log(response.data)
               setData(response.data)
          }
          getAllCustomersKawasan()

     }, [id])

     return (
          <div className="p-2">
               <div className='row g-3 my-2'>
                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Arrears" value={data.total_arrears} icon="fas fa-user" color=" rgb(94, 114, 227)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Debt" value={data.total_debt} icon="fas fa-user" color=" rgb(210, 117, 233)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Credit" value={data.total_credit} icon="fas fa-user" color="orange" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="Total Customers" value={data.count} icon="fas fa-user" color=" rgb(129, 27, 197)" />}
                    </div>
               </div>
               <Table data={data} />
          </div>
     );
}

export default Rental;