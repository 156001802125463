import img from '../../assets/friend1.jpg';
import QRCode from "react-qr-code";
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const Card = ({ data }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card" >
      <div className="card-body p-1">
        <div className='row d-flex justify-content-between'>
          <div className='col-7'>
            <h5 className='text-secondary'>Maklumat Penyewa Pihak Ketiga</h5>
          </div>
          <div ref={componentRef} className='col-4'>
            <QRCode
              size={90}
              bgColor="white"
              fgColor="black"
              value={data.id || ''}
            />
          </div>
        </div>
        <div className='d-flex justify-content-center my-3'>
          <img src={img} className='rounded-circle' style={{ width: '40%' }} />
        </div>
        <div className='d-flex justify-content-between'>
          <button className='btn btn-info'>Sambung</button>
          <button onClick={handlePrint} className='btn btn-secondary'>Print</button>
        </div>
        <div className='card-text mt-3 text-center'>
          <p className='fw-bold m-0 text-sm text-secondary'>
            {data.name || 'N/A'}
          </p>
          <p className='text-secondary' style={{ fontSize: '.7rem' }}>{data.alamat_unit || "N/A"}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
