import React from 'react';

const SliderCard = ({
  title,
  dataActive,
  nonActive,
  keys = {
    activeTotal: 'totalBakiAkhirAktif',
    activeAccounts: 'totalAkaunAktif',
    nonActiveTotal: 'totalBakiAkhirTidakAktif',
    nonActiveAccounts: 'totalAkaunTidakAktif',
    totalBaki: 'totalBakiAkhirAll',
    totalAccounts: 'totalAccounts',
    totalAccounts: 'totalAccounts'

  }
}) => {
  const info = dataActive ? dataActive[keys.totalBaki] || 0 : 0;
  const totalActive = dataActive ? dataActive[keys.activeTotal] || 0 : 0;
  const totalNonActive = dataActive ? dataActive[keys.nonActiveTotal] || 0 : 0;
  const totalActiveLength = dataActive ? dataActive[keys.activeAccounts] || 0 : 0;
  const totalNonActiveLength = dataActive ? dataActive[keys.nonActiveAccounts] || 0 : 0;
  const totalAccounts = dataActive ? dataActive[keys.totalAccounts] || 0 : 0;

  const rentInfo = nonActive ? nonActive.totalCajSemasaAll || 0 : 0;
  const rentActive = nonActive ? nonActive.totalCajSemasaAktif || 0 : 0;
  const rentNonActive = nonActive ? nonActive.totalCajSemasaTidakAktif || 0 : 0;
  const rentActiveLength = nonActive ? nonActive.totalAkaunAktif || 0 : 0;
  const rentNonActiveLength = nonActive ? nonActive.totalAkaunTidakAktif || 0 : 0;


  return (
    <div className="card mx-1 shadow-none">
      <div className="card-body p-0">
        <div className="row">
          <div className="col-lg-2 mb-3 mb-lg-0">
            <div className="card bg-light " style={{ height: "100%" }}>
              <div className="card-body p-0 ">
                <h3 className="card-title text-start">{title}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <div className="card p-2">
              <div className="card-body p-0 px-2">
                <div className="row">
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="font text-primary">AIR</h4>
                    <p className="fw-bolder fs-5 m-0"> {info} (<span className="fw-bolder m-0 text-dark" style={{ fontFamily: "sans-serif" }}>{totalAccounts} AKAUM</span> )
                    </p>

                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-success font">AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0"> {totalActive} (<span className="fw-bolder m-0 text-dark" style={{ fontFamily: "sans-serif" }}>{totalActiveLength} AKAUM</span>)
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-danger font">TIDAK-AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0"> {totalNonActive} (<span className="fw-bolder m-0 text-dark" style={{ fontFamily: "sans-serif" }}>{totalNonActiveLength} AKAUM</span> )</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="card p-2 mt-3">
              <div className="card-body p-0 px-2">
                <div className="row">
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="font text-warning">SEWA</h4>
                    <p className="fw-bolder fs-5 m-0">
                       {rentInfo} (<span className="fw-bolder m-0 text-dark" style={{ fontFamily: "sans-serif" }}>{rentActiveLength} AKAUM</span>)
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 my-2 my-lg-0 text-start">
                    <h4 className="text-success font">AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                     {rentActive} (<span className="fw-bolder m-0 text-dark " style={{ fontFamily: "sans-serif" }}>{rentActiveLength} AKAUM</span>)
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-danger font">TIDAK-AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                       {rentNonActive} (<span className="fw-bolder m-0 text-dark" style={{ fontFamily: "sans-serif" }}>{rentNonActiveLength} AKAUM</span>)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
