import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderCard from "./SliderCard";
import axios from "axios";

function SimpleSlider() {
  const [tungakaanWater, setTungakaanWater] = useState();
  const [bayaraanWater, setBayaraanWater] = useState();
  const [cajJulaiWater, setCajJulaiWater] = useState();
  const [cajJulaiRental, setCajJulaiRental] = useState();
  const [kpi, setKPI] = useState({
    
    totalAccounts: '40%',
    totalAkaunAktif:' 60%',
    totalAkaunTidakAktif:  12344,
    totalCajSemasaAktif: 2321,
    totalCajSemasaAll: '56%',
    totalCajSemasaTidakAktif: '23%'

  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('http://bpohasil.click:3000/api/v1/tungakanWaterStats');
        setTungakaanWater(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('http://bpohasil.click:3000/api/v1/bayaraanWaterStats');
        setBayaraanWater(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('http://bpohasil.click:3000/api/v1/cajJulaiWaterStats');
        setCajJulaiWater(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('http://bpohasil.click:3000/api/v1/cajJulaiRentalStats');
        setCajJulaiRental(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <SliderCard
            title="Jumlah Tungakaan"
            dataActive={tungakaanWater}
            keys={{
              totalBaki: 'totalBakiAkhirAll',
              activeTotal: 'totalBakiAkhirAktif',
              activeAccounts: 'totalAkaunAktif',
              nonActiveTotal: 'totalBakiAkhirTidakAktif',
              nonActiveAccounts: 'totalAkaunTidakAktif',
              totalAccounts: 'totalAccounts'

            }}
          />
        </div>
        <div>
          <SliderCard
            title="Jumlah Bayaraan"
            dataActive={bayaraanWater}
            keys={{
              totalBaki: 'totalBayaranAll',
              activeTotal: 'totalBayaranAktif',
              activeAccounts: 'totalAkaunAktif',
              nonActiveTotal: 'totalBayaranTidakAktif',
              nonActiveAccounts: 'totalAkaunTidakAktif',
              totalAccounts: 'totalAccounts'
            }}
          />
        </div>
        <div>
          <SliderCard
            title="Jumlah Caj"
            dataActive={cajJulaiWater}
            nonActive={cajJulaiRental}
            keys={{
              totalAccounts: 'totalAccounts',
              activeAccounts: 'totalAkaunAktif',
              nonActiveAccounts: 'totalAkaunTidakAktif',
              activeTotal: 'totalBillAktif',
              nonActiveTotal: 'totalBillTidakAktif',
              totalBaki: 'totalBillAll',

            }}
          />
        </div>
        <div>
          <SliderCard
            title="Jumlah KPI"
            dataActive={kpi}
            nonActive={kpi}
            keys={{
              totalAccounts: 'totalAccounts',
              activeAccounts: 'totalAkaunAktif',
              nonActiveAccounts: 'totalAkaunTidakAktif',
              activeTotal: 'totalBillAktif',
              nonActiveTotal: 'totalBillTidakAktif',
              totalBaki: 'totalBillAll',

            }}
          />
        </div>
      </Slider>
    </div>
  );
}

export default SimpleSlider;
