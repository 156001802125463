import img from '../../assets/login-bg1.png'

const IMGCard = () => {
    return (
        <div className="card p-0 rounded-pill" >
            <img src={img} className="card-img" alt="..." style={{ maxHeight: "343px" }} />
            <div className="card-img-overlay" >
                <h3 style={{ position: "absolute", bottom: "0" }}>
                    <div className="icon icon-sm me-3 mb-4 bg-light shadow d-flex justify-content-center align-items-center">
                        <i className="fa-solid fs-6 fa-mobile-screen text-dark opacity-10 "></i>
                    </div>
                </h3>
            </div>
        </div>
    );
}

export default IMGCard;