import { useEffect, useState } from "react";
import axios from "axios";
import Table from "../components/zone/Table";
import Card from "../components/zone/Card";
import MixedChart from "../components/zone/MixedChart";
import Card2 from "../components/zone/Card2";
import IMGCard from "../components/zone/IMGCard";
import { useParams } from "react-router-dom";


const Zone = () => {

    const [card, setCard] = useState()
   
    const { id } = useParams();
    console.log(id)


     useEffect(() => {
          async function getAllCustomersKawasan() {
               const response = await axios.post('http://bpohasil.click:8080/ca/getallzonecustomers',
                    { id: id }
               )
               console.log(response.data)
               setCard(response.data)
          }
          getAllCustomersKawasan()

     }, [id])
    console.log(card)
    return (
        <div className='container-fluid  p-2 p-lg-0'>
       
            {/* cards */}
            <div className='row g-3'>
                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {card && <Card title="Arrears" value={card.total_arrears}  icon="fas fa-user" color=" rgb(94, 114, 227)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {card && <Card title="Debt" value={card.total_debt}  icon="fas fa-user" color=" rgb(210, 117, 233)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {card && <Card title="Credit" value={card.total_credit}  icon="fas fa-user" color="orange" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {card && <Card title="Total Customers" value={card.count}  icon="fas fa-user" color=" rgb(129, 27, 197)" />}
                    </div>
            </div>

            {/* line charts */}
            <div className='row my-1 g-3'>
                <div className=' mb-3 mt-1  mb-lg-0'>
                    <div className="row g-3">
                        <div className="col-lg-7">
                    <div className='card p-0 shadow' style={{maxWidth:'99%'}}>
                        <div className='card-body p-2'>
                            <p className="ms-2 m-0 fw-bold">Aduan Selesai</p>   
                            <div className="pe-1">
                              <MixedChart/>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-5">
                        <IMGCard />
                    </div>
                    </div>
                </div>
        
            </div>
            {/* table & Progress */}
            <div className="row mt-1 g-3">
                <div className="col-lg-7">
                    <Table/>
                </div>
                <div className="col-lg-5">
                    <Card2/>
                </div>
            </div>
        </div>
    );
}

export default Zone;
