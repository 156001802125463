import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import img from '../assets/logo.png';
import axios from 'axios';

const SideBar = () => {
  const [expandedItems, setExpandedItems] = useState({});
  const [zone, setZone] = useState([])

  const toggleItem = (id) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    axios.post('http://bpohasil.click:8080/ca/totalkawasan')
      .then(res => setZone(res.data.totatlKawasan))
      .catch(err => console.log(err))
  },[])
  const zone1 = zone.filter(data => data.zone_id === 1)
  const zone2 = zone.filter(data => data.zone_id === 2)
  const zone3 = zone.filter(data => data.zone_id === 3)
  const zone4 = zone.filter(data => data.zone_id === 4)

  return (
    <div
      id="bdSidebar"
      className="d-flex ms-2 mt-2 flex-column flex-shrink-0  offcanvas-xl offcanvas-start"
      style={{ width: '250px', minHeight: "97vh", borderRadius: '20px' }}
    >
      {/* logo */}
      <NavLink to="/" className="navbar-brand ps-3 pt-3 pb-2">
        <img src={img} className="m-2" style={{ width: '40%' }} alt="Logo" />
        <p className="fw-bold h6 ms-2" style={{ color: 'gray' }}>Data Penyewa</p>
      </NavLink>
      <div style={{ maxHeight: "80vh", overflow: 'auto' }}>
        <ul className="nav nav-pills mt-2 flex-column mynav px-3">
          {/* Dashboard item */}
          <li className="nav-item">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: 'gray', cursor: 'pointer' }}
              onClick={() => toggleItem(1)}
            >
              <span className="fw-light">
                <i className="me-3 fas fa-home" style={{ color: 'orange' }}></i>
                Dashboard
              </span>
              <i
                className={`fas ${expandedItems[1] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                style={{ fontSize: '.7rem' }}
              ></i>
            </p>
            {expandedItems[1] && (
              <ul className="nav flex-column ms-3">
                <li className="nav-item">
                  <NavLink to="/zone/1" className="ps-4 fw-light my-1 text-secondary text-sm nav-link">
                  Zone 1
                  </NavLink>
                  <NavLink to="/zone/2" className="ps-4 fw-light my-1 text-secondary text-sm nav-link">
                  zone 2
                  </NavLink>
                  <NavLink to="/zone/3" className="ps-4 fw-light my-1 text-secondary text-sm nav-link">
                    zone 3
                  </NavLink>
                  <NavLink to="/zone/4" className="ps-4 fw-light my-1 text-secondary text-sm nav-link">
                    Zone 4
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          {/* Customers item */}
          <li className="nav-item">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: 'gray', cursor: 'pointer' }}
              onClick={() => toggleItem(2)}
            >
              <span className="fw-light">
                <i className="me-3 fas fa-users" style={{ color: 'orange' }}></i>
                Customers
              </span>
              <i
                className={`fas ${expandedItems[2] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                style={{ fontSize: '.7rem' }}
              ></i>
            </p>
            {expandedItems[2] && (
              <ul className="nav flex-column ms-3">
                <li className="nav-item">
                  <p
                    className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                    style={{ color: 'gray', cursor: 'pointer' }}
                    onClick={() => toggleItem('2a')}
                  >
                    <span className="fw-light">
                      <i className="me-3 fas fa-building" style={{ color: 'orange' }}></i>
                      Rental
                    </span>
                    <i
                      className={`fas ${expandedItems['2a'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                      style={{ fontSize: '.7rem' }}
                    ></i>
                  </p>
                  {expandedItems['2a'] && (
                    <ul className="nav flex-column ms-3">
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2a1')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 1
                          </span>
                          <i
                            className={`fas ${expandedItems['2a1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2a1'] && (
                          <ul className="nav flex-column ms-3">
                            {zone1.map((item, index) => (
                              <>
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/rental/zone1/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                         
                              </>
                            ))}
                                 <li>
                               <NavLink to={`/zone`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                 demo
                                </NavLink>
                              </li>
                          </ul>
                        )}
                      </li>
                      {/* Zone 2 Links */}
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2a2')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 2
                          </span>
                          <i
                            className={`fas ${expandedItems['2a2'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2a2'] && (
                          <ul className="nav flex-column ms-3">
                            {zone2.map((item, index) => (
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/rental/zone2/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                      {/* Zone-3 */}
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2a3')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 3
                          </span>
                          <i
                            className={`fas ${expandedItems['2a3'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2a3'] && (
                          <ul className="nav flex-column ms-3">
                            {zone3.map((item, index) => (
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/rental/zone3/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                      {/* Zone-4 */}
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2a4')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 4
                          </span>
                          <i
                            className={`fas ${expandedItems['2a4'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2a4'] && (
                          <ul className="nav flex-column ms-3">
                            {zone4.map((item, index) => (
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/rental/zone4/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </ul>
                  )}
                </li>
                {/* Water start */}
                <li className="nav-item">
                  <p
                    className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                    style={{ color: 'gray', cursor: 'pointer' }}
                    onClick={() => toggleItem('2b')}
                  >
                    <span className="fw-light">
                      <i className="me-3 fas fa-tint" style={{ color: 'orange' }}></i>
                      Water
                    </span>
                    <i
                      className={`fas ${expandedItems['2b'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                      style={{ fontSize: '.7rem' }}
                    ></i>
                  </p>
                  {expandedItems['2b'] && (
                    <ul className="nav flex-column ms-3">
                      {/* zone1 */}
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2b1')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 1
                          </span>
                          <i
                            className={`fas ${expandedItems['2b1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2b1'] && (
                          <ul className="nav flex-column ms-3">
                            {zone1.map((item, index) => (
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/water/zone1/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                      {/* Water-Zone-2 */}
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2b2')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 2
                          </span>
                          <i
                            className={`fas ${expandedItems['2b2'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2b2'] && (
                          <ul className="nav flex-column ms-3">
                            {zone2.map((item, index) => (
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/water/zone2/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                      {/* Water-Zone-3 */}
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2b3')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 3
                          </span>
                          <i
                            className={`fas ${expandedItems['2b3'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2b3'] && (
                          <ul className="nav flex-column ms-3">
                            {zone3.map((item, index) => (
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/water/zone3/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                      {/* Water-Zone-4 */}
                      <li className="nav-item">
                        <p
                          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                          style={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => toggleItem('2b4')}
                        >
                          <span className="fw-light">
                            <i className="me-2 fa-solid fa-location-dot" style={{ color: "orange" }}></i>Zone 4
                          </span>
                          <i
                            className={`fas ${expandedItems['2b4'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            style={{ fontSize: '.7rem' }}
                          ></i>
                        </p>
                        {expandedItems['2b4'] && (
                          <ul className="nav flex-column ms-3">
                            {zone4.map((item, index) => (
                              <li key={index} className="nav-item">
                                <NavLink to={`/customers/water/zone4/${item.kawasan_no}`} className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                                  {item.nama}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            )}
          </li>
          {/* uploader */}
          <li className="nav-item">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: 'gray', cursor: 'pointer' }}
              onClick={() => toggleItem(4)}
            >
              <span className="fw-light">
                <i className="me-3 fa-solid fa-cloud-arrow-up" style={{ color: 'orange' }}></i>
                Uploader
              </span>
              <i
                className={`fas ${expandedItems[4] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                style={{ fontSize: '.7rem' }}
              ></i>
            </p>
            {expandedItems[4] && (
              <ul className="nav flex-column ms-3">
                <li>
                  <NavLink to="/rental_uploader" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                    Rental
                  </NavLink>

                </li>
                <li><NavLink to="/water_uploader" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                  Water
                </NavLink></li>

              </ul>
            )}
          </li>

          {/* Reports item */}
          <li className="nav-item">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: 'gray', cursor: 'pointer' }}
              onClick={() => toggleItem(3)}
            >
              <span className="fw-light">
                <i className="me-3 fas fa-chart-line" style={{ color: 'orange' }}></i>
                Reports
              </span>
              <i
                className={`fas ${expandedItems[3] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                style={{ fontSize: '.7rem' }}
              ></i>
            </p>
            {expandedItems[3] && (
              <ul className="nav flex-column ms-3">
                <li className="nav-item">
                  <p
                    className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                    style={{ color: 'gray', cursor: 'pointer' }}
                    onClick={() => toggleItem('2c1')}
                  >
                    <span className="fw-light">
                      <i class=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>TO Report
                    </span>
                    <i
                      className={`fas ${expandedItems['2c1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                      style={{ fontSize: '.7rem' }}
                    ></i>
                  </p>
                  {expandedItems['2c1'] && (
                    <ul className="nav flex-column ms-3">
                      <li className="nav-item">
                        <NavLink to="/reports/total-outstanding-by-month" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          TOTAL OUTSTANDING BY MONTH
                        </NavLink>
                        <NavLink to="/reports/total-outstanding-report-by-zone" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          TOTAL OUTSTANDING REPORT BY ZONE
                        </NavLink>
                        <NavLink to="/reports/total-outstanding-report-by-areas" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          TOTAL OUTSTANDING REPORT BY AREAS
                        </NavLink>
                        <NavLink to="/reports/total-outstanding-report-by-units" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          TOTAL OUTSTANDING REPORT BY UNITS
                        </NavLink>
                        <NavLink to="/reports/report-by-graph" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          REPORT BY GRAPH
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
                {/* Report-2 */}
                <li className="nav-item">
                  <p
                    className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                    style={{ color: 'gray', cursor: 'pointer' }}
                    onClick={() => toggleItem('2c2')}
                  >
                    <span className="fw-light">
                      <i class=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>TC Report
                    </span>
                    <i
                      className={`fas ${expandedItems['2c2'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                      style={{ fontSize: '.7rem' }}
                    ></i>
                  </p>
                  {expandedItems['2c2'] && (
                    <ul className="nav flex-column ms-3">
                      <li className="nav-item">
                        <NavLink to="/reports/total-collection-by-month" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          REPORT TOTAL COLLECTION BY MONTH
                        </NavLink>
                        <NavLink to="/reports/total-collection-by-zone" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          REPORT TOTAL COLLECTION BY ZONE
                        </NavLink>
                        <NavLink to="/reports/total-collection-by-areas" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                          REPORT TOTAL COLLECTION BY AREAS
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
