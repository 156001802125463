
// all style
import './assets/css/argon-dashboard.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

// React-Routers-links
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// components
import SideBar from './components/SideBar';
import Navbar from './components/Navbar';
// layouts
import Login from './Layouts/Login';

import Profile from './Layouts/Profile';
import Uploader from './components/uploader/rental/Uploader';
import ZoneDetial from './components/ZoneDetail';
import WaterUploader from './components/uploader/water/WaterUploader';
import Water from './Layouts/Water';
import Rental from './Layouts/Rental';

import Zone from './Layouts/Zone';
import Index from './Layouts/Index';

function App() {
  const token = localStorage.getItem('token')

  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
        </Routes>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <div className="App" style={{ minHeight: '100vh', width: '100%' }}>
          <div className="container-fluid p-0 d-flex h-100" style={{ height: '100vh' }}>
            <SideBar />
            <div className="bg flex-fill" style={{maxHeight:"99vh",overflow:"auto"}}>
              <Navbar />
              <div className="py-3 px-lg-3">
                <Routes>

                  <Route path='/' element={<Index />} />
                  <Route path='/zone/:id' element={<Zone />} />
                  
                  <Route path='/profile/:id' element={<Profile />} />
                  <Route path='/water_uploader' element={<WaterUploader />} />
                  <Route path='/rental_uploader' element={<Uploader />} />
                  {/* Rental */}
                  <Route path='/customers/Rental/zone1/:id' element={<Rental/>}/>
                  <Route path='/customers/Rental/zone2/:id' element={<Rental/>}/>
                  <Route path='/customers/Rental/zone3/:id' element={<Rental/>}/>
                  <Route path='/customers/Rental/zone4/:id' element={<Rental/>}/>
                  {/* water */}
                  <Route path='/customers/water/zone1/:id' element={<Water/>}/>
                  <Route path='/customers/water/zone2/:id' element={<Water/>}/>
                  <Route path='/customers/water/zone3/:id' element={<Water/>}/>
                  <Route path='/customers/water/zone4/:id' element={<Water/>}/>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    )
  }

}

export default App;