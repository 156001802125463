import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

  // logout
const handleLogout = () =>{
  localStorage.removeItem('token')
  window.location.replace('/');
}


  return (
    <nav className="navbar navbar-expand shadow-none">
      <div className="container-fluid py-1 px-1">
        <div className="row d-flex align-items-center" style={{ width: '100%' }}>
          <div className="col-lg-7 col-xl-8">
            <nav>
              <ol className="breadcrumb my-1 bg-transparent text-light my-lg-0 fw-bold">
                <li className="breadcrumb-item">Sistem Pangkalan Data BPO</li>
              </ol>
            </nav>
          </div>

          <div className="col-lg-5 col-xl-4 p-0 ps-3">
            <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 d-flex align-items-center" id="navbar">
              <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                {/* Options */}
                <div className="input-group">
                  <div className="dropdown">
                    <button className="btn dropdown-toggle p-0 p-2 m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                      <span className="pe-5 m-0 fw-light text-sm">Pilih Zon</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li ><Link className="dropdown-item" href="#">Rental</Link></li>
                        <li ><Link className="dropdown-item" href="#">Water</Link></li>
                       
                    </ul>
                  </div>
                </div>
                {/* Option-End */}
              </div>
              <ul className="navbar-nav justify-content-end">
                <li className="nav-item d-flex align-items-center"  onClick={handleLogout}  style={{cursor:"pointer"}}>
                  <span className=" text-white font-weight-bold px-0" target="_blank">
                    <i className="fa fa-user me-sm-1"></i>
                    <span className="d-sm-inline d-none">Logout</span>
                  </span>
                </li>
                {/* menu-button */}
                <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a  className="nav-link text-white p-0" id="iconNavbarSidenav"  data-bs-toggle="offcanvas" data-bs-target="#bdSidebar">
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line bg-white"></i>
                  <i className="sidenav-toggler-line bg-white"></i>
                  <i className="sidenav-toggler-line bg-white"></i>
                </div>
              </a>
            </li>
                <li className="nav-item px-3 d-flex align-items-center">
                  <Link to="" className="nav-link text-white p-0">
                    <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                  </Link>
                </li>
                {/* message */}
                <li className="nav-item dropdown pe-2 d-flex align-items-center">
                  <a className="nav-link text-white p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-bell cursor-pointer"></i>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                      <li className="mb-2" >
                        <Link to="" className="dropdown-item border-radius-md">
                          <div className="d-flex py-1">
                            <div className="my-auto">
                              <img src="" className="avatar avatar-sm me-3" alt="user image" />
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="text-sm font-weight-normal mb-1">
                                <span className="font-weight-bold">New Message</span> From Afiq
                              </h6>
                              <p className="text-xs text-secondary mb-0">
                                <i className="fa fa-clock me-1"></i> 1 min ago
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li className="mb-2" >
                        <Link to="" className="dropdown-item border-radius-md">
                          <div className="d-flex py-1">
                            <div className="my-auto">
                              <img src="" className="avatar avatar-sm me-3" alt="user image" />
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="text-sm font-weight-normal mb-1">
                                <span className="font-weight-bold">New Message</span> From Afiq
                              </h6>
                              <p className="text-xs text-secondary mb-0">
                                <i className="fa fa-clock me-1"></i> 1 min ago
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li className="mb-2" >
                        <Link to="" className="dropdown-item border-radius-md">
                          <div className="d-flex py-1">
                            <div className="my-auto">
                              <img src="" className="avatar avatar-sm me-3" alt="user image" />
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className="text-sm font-weight-normal mb-1">
                                <span className="font-weight-bold">New Message</span> From Afiq
                              </h6>
                              <p className="text-xs text-secondary mb-0">
                                <i className="fa fa-clock me-1"></i> 1 min ago
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                  </ul>
                </li>
                {/* Message-end */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
