import React, { useState } from 'react';
import axios from 'axios';

const Form = ({data}) => {
  const [formData, setFormData] = useState({
    namaPenyewa: '',
    umur: '',
    jantina: '',
    tahapPendidikan: '',
    nomborUnit: '',
    nomborKadPengenalan: '',
    bangsa: '',
    jenisPekerjaan: '',
    namaKawasan: '',
    nomborTelefon: '',
    tarikhKuatkuasa: '',
    statusAkaun: '',
    alamat: '',
    bandar: '',
    negara: '',
    poskod: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('your-api-endpoint', formData)
      .then(response => {
        console.log('Data submitted successfully', response);
      })
      .catch(error => {
        console.error('There was an error submitting the data', error);
      });
  };

  return (
    <div className='card'>
        <div className='card-body p-1'>
      <div className="d-flex justify-content-between">
        <h4 className="text-secondary">Sunting</h4>
        <div>
        {data.status?
           <span className={`badge  badge-success m-auto `}>Active</span>:
           <span className={`badge  badge-danger m-auto`}>Not Active</span>
      }
        </div>
      </div>
      <p className="text-secondary fw-medium">MAKLUMAT PENYEWA</p>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-3">
              <label className="form-label">Nama Penyewa</label>
              <input
                type="text"
                className="form-control"
                name="namaPenyewa"
                placeholder="Muhammad Afiq Zikri Bin Zulkifli"
                value={formData.namaPenyewa}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 d-flex">
              <div className="me-2">
                <label className="form-label">Umur</label>
                <input
                  type="text"
                  className="form-control"
                  name="umur"
                  placeholder="36 Tahun"
                  value={formData.umur}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="form-label">Jantina</label>
                <input
                  type="text"
                  className="form-control"
                  name="jantina"
                  placeholder="Lelaki"
                  value={formData.jantina}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3 d-flex">
              <div className="me-2">
                <label className="form-label">Tahap Pendidikan</label>
                <input
                  type="text"
                  className="form-control"
                  name="tahapPendidikan"
                  placeholder="Ijazah Sarjana Muda"
                  value={formData.tahapPendidikan}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="form-label">Nombor Unit</label>
                <input
                  type="text"
                  className="form-control"
                  name="nomborUnit"
                  placeholder="G-23"
                  value={formData.nomborUnit}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label className="form-label">Nombor Kad Pengenalan</label>
              <input
                type="text"
                className="form-control"
                name="nomborKadPengenalan"
                placeholder="9847684855434665"
                value={formData.nomborKadPengenalan}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 d-flex">
              <div className="me-2">
                <label className="form-label">Bangsa</label>
                <input
                  type="text"
                  className="form-control"
                  name="bangsa"
                  placeholder="Melayu"
                  value={formData.bangsa}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="form-label">Jenis Pekerjaan</label>
                <input
                  type="text"
                  className="form-control"
                  name="jenisPekerjaan"
                  placeholder="Swasta"
                  value={formData.jenisPekerjaan}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-3 d-flex">
              <div className="me-2">
                <label className="form-label">Nama Kawasan</label>
                <input
                  type="text"
                  className="form-control"
                  name="namaKawasan"
                  placeholder="Pasar Harian Selayang Kuala Lumpur"
                  value={formData.namaKawasan}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="form-label">Nombor Telefon</label>
                <input
                  type="text"
                  className="form-control"
                  name="nomborTelefon"
                  placeholder="017-71927397"
                  value={formData.nomborTelefon}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex my-3 row">
          <div className="col-lg-4">
            <label className="form-label">Tarikh Kuatkuasa</label>
            <input
              type="date"
              className="form-control"
              name="tarikhKuatkuasa"
              placeholder="06/07/2018"
              value={formData.tarikhKuatkuasa}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-8">
          <label className="form-label">Status Akaun</label>

            <div className="d-flex">
              <div className="form-check p-0">
                <input
                  className="form-check-input m-0 mt-1 me-1"
                  type="radio"
                  name="statusAkaun"
                  id="statusDiduduki"
                  value="Diduduki"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="statusDiduduki">
                  Diduduki
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input  m-0 mt-1 me-1"
                  type="radio"
                  name="statusAkaun"
                  id="statusDitinggalkan"
                  value="Ditinggalkan"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="statusDitinggalkan">
                  Ditinggalkan
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input  m-0 mt-1 me-1"
                  type="radio"
                  name="statusAkaun"
                  id="statusKosong"
                  value="Kosong"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="statusKosong">
                  Kosong
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="my-3">
          <p className="text-secondary fw-medium">MAKLUMAT PENYEWA PIHAK KETIGA</p>
          <div>
            <label className="form-label">Alamat</label>
            <input
              type="text"
              className="form-control"
              name="alamat"
              placeholder="G-23, Pasar Harian Selayang Kuala Lumpur, Jalan Selayang Baru, 68100 Batu Caves, Selangor"
              value={formData.alamat}
              onChange={handleChange}
            />
          </div>
          <div className="row mt-3">
            <div className="col-lg-4">
              <div>
                <label className="form-label">Bandar</label>
                <input
                  type="text"
                  className="form-control"
                  name="bandar"
                  placeholder="Kuala Lumpur"
                  value={formData.bandar}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <label className="form-label">Negara</label>
                <input
                  type="text"
                  className="form-control"
                  name="negara"
                  placeholder="Malaysia"
                  value={formData.negara}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <label className="form-label">Poskod</label>
                <input
                  type="text"
                  className="form-control"
                  name="poskod"
                  placeholder="437300"
                  value={formData.poskod}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
      </div>
    </div>
  );
};

export default Form;
